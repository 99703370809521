/* OrthoPrepModal.module.css */

.customOrthoModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Modal Header */
.customOrthoModal .modal-header {
  background-color: rgb(78, 85, 76);
  color: #fff;
  border-bottom: 1px solid #dee2e6;
}

/* Modal Body */
.customOrthoModal .modal-body {
  text-align: center;
}

.customOrthoModal .modal-body p {
  letter-spacing: 0.1ch;
  word-spacing: 0.2ch;
}

/* Form Labels */
.customOrthoModal .form-label {
  font-weight: bold;
}

/* Form Select Controls */
.customOrthoModal .form-control {
  margin-bottom: 15px;
  width: 50%; /* Adjust the width as needed */
  margin-left: auto;
  margin-right: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.customOrthoModal .form-control:focus {
  border-color: #51d899;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 180, 110, 0.25);
}

/* Checkboxes and Select Options */
.customOrthoModal .form-check-label,
.customOrthoModal .form-control option {
  padding: 5px;
}

.customOrthoModal .form-control option {
  display: block;
  width: 100%;
  text-align: center;
}

/* Buttons */
.customOrthoModal .btn-primary {
  letter-spacing: 0.1ch;
  background-color: #57c083;
  border-color: #57c083;
  color: #fff;
}

.customOrthoModal .btn-primary:hover {
  background-color: rgb(115, 175, 91);
  border-color: rgb(115, 175, 91);
}

.customOrthoModal .btn-secondary {
  margin-left: 25px;

  letter-spacing: 0.1ch;
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.customOrthoModal .btn-secondary:hover {
  background-color: #545b62;
  border-color: #545b62;
}

.images_list {
  width: 100%; /* Adjust the width as needed */
  /* Set a maximum width if needed */
  height: auto;
  max-height: 350px;
  overflow-y: auto;
}
/* Disable pointer events for the backdrop when the modal is open */
.customOrthoModal .modal-backdrop.show {
  pointer-events: none;
}

/* Checkboxes */
.customOrthoModal .form-check {
  margin-right: 10px; /* Adjust spacing between checkboxes as needed */
  font-size: 0.8rem;
}

.customOrthoModal .form-check-input {
  display: none; /* Hide the default checkbox input */
}

.customOrthoModal .form-check-label {
  display: inline-block;
  padding: 8px 12px; /* Adjust padding as needed */
  border-radius: 20px; /* Creates a capsule effect */
  background-color: #e9ecef; /* Set the background color for the capsule */
  color: #495057;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.customOrthoModal .form-check-input:checked + .form-check-label {
  background-color: #57c083; /* Set the background color for the checked state */
  border-color: #57c083; /* Set the border color for the checked state */
  color: #fff; /* Set the text color for the checked state */
}

.customOrthoModal .form-check-label:hover {
  background-color: #d8d8d8; /* Set the background color for the hover state */
}

/* Apply styling to the select dropdown */
.customOrthoModal .form-control-images-list {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #495057;
  cursor: pointer;
  width: 80%; /* Adjust the width as needed */

  height: auto;
  max-height: 150px;
  overflow-y: auto;
}

.customOrthoModal .form-control-images-list:focus {
  border-color: #51d899;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 180, 110, 0.25);
}

/* Apply styling to the dropdown options */
.customOrthoModal .form-control-images-list option {
  padding: 8px 12px;
  margin-top: 10px;
  border-radius: 20px;
  background-color: #e9ecef;
  color: #495057;
}

.customOrthoModal .form-control-images-list option:checked {
  background-color: #57c083;
  border-color: #57c083;
  color: #fff;
}
