/* Style the container for search results */
.search-results {
  position: absolute;
  width: 300px;
  height: 500px;
  overflow-y: auto;
  z-index: 100;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: opacity 0.3s ease-in-out; /* Add a smooth fade-in/fade-out effect */
}

/* Style the list of results */
.search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center; /* Center text within the list */
}

/* Style each list item with a button */
.search-result-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the items span the full width */
}

/* Add a hover effect */
.search-result-item:hover {
  background-color: #e5e5e5; /* Slightly darker background on hover */
}

/* Style the button inside the list item */
.search-result-item button {
  background-color: #57c083; /* Background color for the button */
  border: none;
  padding: 10px 20px; /* Add padding for a larger button */
  font-size: 12px; /* Increase the font size */
  color: #fff; /* Text color */
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

/* Style the button text */
.search-result-item button:focus {
  text-decoration: underline;
  background-color: #0056b3; /* Darker background on focus */
  transform: scale(1.05); /* Slight scale-up effect on focus */
}

/* Add a hover effect */
.search-result-item button:hover {
  background-color: #0056b3; /* Darker background on hover */
  transform: scale(1.05); /* Slight scale-up effect on hover */
}
