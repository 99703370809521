body {
  /* background-color: #eeeff1; */
  font-family: 'Inter', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"',
    'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"',
    '"Segoe UI Emoji"', '"Segoe UI Symbol"';
}
.icon-root {
  fill: none !important;
  stroke: #1d2023;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jobs {
  margin: auto;
  padding: 10px;
}

.kill-btn {
  min-width: 80px;
}

.status-badge {
  min-width: 65px;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.jobs {
  padding: 8px 12px !important;
}
.jobTable {
  background-color: #fff;
  max-width: 100%;
}

.tableContainer {
  background-color: #fff;
}

.navbar .container-fluid > div {
  display: flex;
  align-items: center;
}

.navbar .container-fluid > div > .navbar-toggler {
  height: 40px;
}

.navbar .navbar-brand {
  display: flex;
  align-items: end;
  margin: 0 10px;
}

.navbar .navbar-brand > img {
  margin-right: 10px;
}
