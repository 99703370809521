.signUpRoot {
  text-align: center;
  padding: 12px;
}
.logo {
  margin-top: 24px;
}
.infoCard {
  background-color: #fff;
  max-width: 360px;
  display: inline-block;
  padding: 20px 40px;
  border-radius: 8px;
  margin-top: 24px;
}
.signUpInput {
  margin-bottom: 1em !important;
}
.termsAndConditions {
  margin-bottom: 1em;
}
.termsAndConditionsLabel {
  font-size: 12px;
  text-align: left;
  color: #949ea7;
}
.pigeonImg {
  margin-top: 4em;
}
.validationError {
  color: red;
  font-size: 12px;
}

.signinBtn {
  width: 100%;
}

.signinTitle {
  font-weight: 600;
  margin: 24px 0px;
}
.signinLabel {
  color: #6e7072;
  margin: 16px 0;
}
