.alert-box {
  padding-top: 20px;
  margin-top: 20px;
  h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
  }
}

.last-update {
  padding: 8px; /* Padding for content */
  border-radius: 6px; /* Border radius for the block */

  transition: transform 0.2s ease; /* Transition effect on hover */
}
